import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../designs.css";
import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";
import { preloadImages } from "../utils";

import { useScroll, motion, useTransform } from "framer-motion";

function Home() {
  const [isElementVisible, setIsElementVisible] = useState(true);

  useEffect(() => {
    // Función para manejar el evento de desplazamiento
    const handleScroll = () => {
      // Calcula la posición actual de desplazamiento
      const scrollY = window.scrollY;

      // Decide si el elemento debe estar visible o no
      const shouldElementBeVisible = scrollY < 50; // Cambia este valor según tus necesidades

      // Actualiza el estado para controlar la visibilidad del elemento
      setIsElementVisible(shouldElementBeVisible);
    };

    // Agrega el evento de escucha cuando el componente se monta
    window.addEventListener("scroll", handleScroll);

    // Limpia el evento de escucha cuando el componente se desmonta
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const { scrollYProgress } = useScroll();

  const xOffset = useTransform(scrollYProgress, [0, 1], [0, -50]);
  const xOffset2 = useTransform(scrollYProgress, [0, 1], [0, 50]);

  useEffect(() => {
    // Pre-carga imágenes y fuentes antes de inicializar LocomotiveScroll
    Promise.all([
      preloadImages(".tiles__line-img"), // Ajusta el selector según tu contenido
    ]).then(() => {
      // Eliminar clase "loading" del body
      document.body.classList.remove("loading");

      // Inicializar LocomotiveScroll
      const scroll = new LocomotiveScroll({
        el: document.querySelector("[data-scroll-container]"), // Referencia al contenedor principal
        smooth: true,
        lerp: 0.1,
        multiplier: 0.5,
        smartphone: {
          smooth: true,
        },
        tablet: {
          smooth: true,
        },
      });

      // Limpieza: Destruir LocomotiveScroll al desmontar el componente
      return () => {
        if (scroll) scroll.destroy();
      };
    });
  }, []);

  return (
    <div data-scroll-container>
      <div className="principal">
        <div className="name-text">
          <motion.h1
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            style={{ zIndex: 2, lineHeight: 0.7, fontSize: 100 }}
          >
            Eva
          </motion.h1>
          <motion.h1
            initial={{ opacity: 0, x: -400 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            style={{
              zIndex: 2,
              lineHeight: 1,
              fontSize: 100,
              paddingLeft: "5%",
            }}
          >
            Victoria
          </motion.h1>
        </div>
        <motion.p initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className="portfolio-text">
          PORTFOLIO
        </motion.p>

        <p className={`element ${isElementVisible ? "" : "hidden"}`}>Copyright ©</p>

        <div className="multimedia-text">
          <h2 className="multimedia-title">Multimedia</h2>
          <p
            style={{
              fontSize: 10,
              fontWeight: 600,
              marginRight: "2%",
              lineHeight: 0.5,
            }}
          >
            Creative.
          </p>
          <hr className="horizontal-line" />{" "}
        </div>

        <div className="circle1"></div>
        <div className="circle2"></div>
        <div className="circle3"></div>
      </div>

      <div className="bestWorks">
        <div className="titulo">
          <h1 className="highlighted-text">Highlighted</h1>
          <h1>Works</h1>
        </div>

        <section className="tiles tiles--perspective tiles--darker">
          <div className="tiles__wrap">
            <div
              className="tiles__line"
              data-scroll
              data-scroll-speed="2"
              data-scroll-direction="horizontal"
              style={{ textAlign: "right", marginTop: "2vh", fontSize: "10px" }}
            >
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_run.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_press.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/mockup.jpg)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/rdeco1.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_run.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/pink.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/IC_1.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_portrait.jpg)" }}></div>
            </div>
            <div
              className="tiles__line"
              data-scroll
              data-scroll-speed="-2"
              data-scroll-direction="horizontal"
              style={{ textAlign: "right", marginTop: "2vh", fontSize: "10px" }}
            >
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/boat.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/floorRoom.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/columns1.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_press.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/rdeco1.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/IC_2.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/text.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/pinkLandscape.png)" }}></div>
            </div>
            <div
              className="tiles__line"
              data-scroll
              data-scroll-speed="2"
              data-scroll-direction="horizontal"
              style={{ textAlign: "right", marginTop: "2vh", fontSize: "10px" }}
            >
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/rdeco2.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_portrait2.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/Finales.jpg)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_laces.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/columns2.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_sneakers.jpg)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/floorRoom.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/rdeco1.png)" }}></div>
            </div>
            <div
              className="tiles__line"
              data-scroll
              data-scroll-speed="-2"
              data-scroll-direction="horizontal"
              style={{ textAlign: "right", marginTop: "2vh", fontSize: "10px" }}
            >
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_run.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/rdeco4.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/text.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/IC_1.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_portrait2.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/mockup.jpg)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/columns1.png)" }}></div>
              <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/IC_2.png)" }}></div>
            </div>
          </div>
          <div className="title-wrapper-home">
            <h2 style={{ fontSize: "10vw" }}>Generative AI</h2>
            <h3 style={{ fontSize: "5vw", color: "#212529" }}>— ComfyUI</h3>
          </div>
          <div className="cta-wrapper-home">
            <Link to="/GenerativeAI">
              <button className="button">Read more</button>
            </Link>
          </div>
        </section>

        <div className="first-work">
          <div className="text-work">
            <h1 className="titulo-work">Final Degree Project</h1>
            <p style={{ paddingLeft: "20%" }}>
              It all began with the concept of transforming one of the textbooks commonly used in classrooms today into a more interactive and dynamic
              learning resource, without forsaking the traditional printed version. The result was a wholly innovative and interactive digital
              edition, complemented by a physical book.
            </p>
            <div className="button-aligned">
              <Link to="/finaldegreeproject">
                <button className="button">Read more</button>
              </Link>
            </div>
          </div>
          <div className="img-container">
            <img className="img-fluid" alt="Revista 1" src={"./img/TwoLaptops.png"} />
          </div>
        </div>

        <div className="first-work">
          <div className="text-work">
            <h1 className="titulo-work">Appo App</h1>
            <p style={{ paddingLeft: "20%" }}>
              This collaborative project served as our university's final assignment, where we developed a mobile application using React Native. Our
              goal was to create a realistic app experience by connecting it to the Unsplash API, allowing us to access a vast database of images.
              Prior to the development phase, we meticulously designed all the elements using Figma, ensuring a polished and thoughtful user interface
              before diving into the coding process.
            </p>
          </div>
          <div className="app-row" style={{ marginBottom: "5%" }}>
            <motion.img className="phone-left" alt="Phone screen home" src={"./img/phoneCategories.png"} style={{ x: xOffset }} />

            <img className="phone-central" alt="Phone screen home" src={"./img/phoneCentral.png"} />
            <motion.img className="phone-right" alt="Phone screen home" src={"./img/phoneUser.png"} style={{ x: xOffset2 }} />
          </div>
        </div>

        <div className="first-work">
          <div className="text-work">
            <h1 className="titulo-work">Cleopatra</h1>
            <p style={{ paddingLeft: "20%" }}>
              One of my most ambitious 3D projects involved the complete creation of a character, from conceptualization to final rigging and
              animation. I began with the character's concept and brought it to life using ZBrush for the initial modeling, employing various
              techniques to optimize polygon count effectively. Subsequently, I meticulously textured the character's elements using Substance
              Painter, adding depth and realism to the design. The final steps included rigging and animation, accomplished using Maya.
            </p>
          </div>
          <div className="img-container">
            <img className="img-fluid-cleopatra" alt="Revista 1" src={"./img/cleopatra1.png"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
