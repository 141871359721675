import React, { useState, useRef, useEffect } from "react";
import "../src/SliderReveal.css";

const SliderReveal = ({ image1, image2 }) => {
  const [sliderPosition, setSliderPosition] = useState(50);
  const sliderContainerRef = useRef(null);

  const handleMouseMove = (e) => {
    if (sliderContainerRef.current) {
      const rect = sliderContainerRef.current.getBoundingClientRect();
      const position = ((e.clientX - rect.left) / rect.width) * 100;
      setSliderPosition(Math.min(Math.max(position, 0), 100));
    }
  };

  const handleTouchMove = (e) => {
    if (sliderContainerRef.current) {
      const rect = sliderContainerRef.current.getBoundingClientRect();
      const touch = e.touches[0];
      const position = ((touch.clientX - rect.left) / rect.width) * 100;
      setSliderPosition(Math.min(Math.max(position, 0), 100));
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleTouchStart = (e) => {
    e.preventDefault(); // Esto ahora debería funcionar
    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    document.addEventListener("touchend", handleTouchEnd);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleTouchEnd = () => {
    document.removeEventListener("touchmove", handleTouchMove);
    document.removeEventListener("touchend", handleTouchEnd);
  };

  useEffect(() => {
    const sliderContainer = sliderContainerRef.current;

    // Registra los eventos de touchstart y mousedown fuera de React para controlarlo manualmente
    if (sliderContainer) {
      sliderContainer.addEventListener("touchstart", handleTouchStart, { passive: false });
      sliderContainer.addEventListener("mousedown", handleMouseDown);
    }

    // Limpia los listeners cuando el componente se desmonte
    return () => {
      if (sliderContainer) {
        sliderContainer.removeEventListener("touchstart", handleTouchStart);
        sliderContainer.removeEventListener("mousedown", handleMouseDown);
      }
    };
  }, []);

  return (
    <div className="slider-container" ref={sliderContainerRef}>
      {/* Imagen de fondo */}
      <img src={image1} alt="Imagen 1" className="slider-image background" />

      {/* Contenedor de la imagen frontal */}
      <div
        className="slider-reveal"
        style={{
          width: `${sliderPosition}%`,
        }}
      >
        <img src={image2} alt="Imagen 2" className="slider-image front" />
      </div>

      {/* Línea divisoria visual */}
      <div
        className="slider-divider"
        style={{
          left: `${sliderPosition}%`,
        }}
      />
    </div>
  );
};

export default SliderReveal;
