import React from "react";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom"; // Importa NavLink
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

import "./NavbarStyles.css";

const MyNavbar = () => {
  const location = useLocation();
  const showMenu = location.pathname !== "/demo" && location.pathname !== "/saber-mes";

  return (
    <div>
      {showMenu && (
        <Navbar expand="lg" className="my-custom-navbar" fixed="top">
          <Container fluid>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggler" />

            <Navbar.Collapse>
              <Nav className="prueba">
                <Nav.Link as={NavLink} className="nav-link" to="/GenerativeAI">
                  Generative AI
                </Nav.Link>
                <Dropdown>
                  <Dropdown.Toggle variant="transparent" id="dropdown-basic" className="nav-link">
                    Other Works
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    <Dropdown.Item>
                      <Link to="/design">
                        <button className="button-menu">Design</button>
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link to="/appWeb">
                        <button className="button-menu">App & web</button>
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link to="/3d">
                        <button className="button-menu">3D</button>
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link to="/finaldegreeproject">
                        <button className="button-menu">Final degree project</button>
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Nav.Link as={NavLink} className="nav-link" to="/contact">
                  Contact
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
    </div>
  );
};

export default MyNavbar;
