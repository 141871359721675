import React, { useEffect, useRef } from "react";
import LocomotiveScroll from "locomotive-scroll";
import "../AI.css";
import SliderReveal from "../SliderReveal.js";
import { preloadImages } from "../utils";

function AI() {
  useEffect(() => {
    // Pre-carga imágenes y fuentes antes de inicializar LocomotiveScroll
    Promise.all([
      preloadImages(".tiles__line-img"), // Ajusta el selector según tu contenido
    ]).then(() => {
      // Eliminar clase "loading" del body
      document.body.classList.remove("loading");

      // Inicializar LocomotiveScroll
      const scroll = new LocomotiveScroll({
        el: document.querySelector("[data-scroll-container]"), // Referencia al contenedor principal
        smooth: true,
        lerp: 0.1,
        multiplier: 0.5,
        smartphone: {
          smooth: true,
        },
        tablet: {
          smooth: true,
        },
      });

      // Limpieza: Destruir LocomotiveScroll al desmontar el componente
      return () => {
        if (scroll) scroll.destroy();
      };
    });
  }, []);

  return (
    <div className="ai-page" data-scroll-container>
      <section className="tiles tiles--perspective tiles--darker" data-scroll-section>
        <div className="tiles__wrap">
          <div
            className="tiles__line"
            data-scroll
            data-scroll-speed="2"
            data-scroll-direction="horizontal"
            style={{ textAlign: "right", marginTop: "2vh", fontSize: "10px" }}
          >
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_run.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_press.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/mockup.jpg)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/rdeco1.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_run.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/pink.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/IC_1.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_portrait.jpg)" }}></div>
          </div>
          <div
            className="tiles__line"
            data-scroll
            data-scroll-speed="-2"
            data-scroll-direction="horizontal"
            style={{ textAlign: "right", marginTop: "2vh", fontSize: "10px" }}
          >
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/boat.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/floorRoom.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/columns1.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_press.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/rdeco1.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/IC_2.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/text.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/pinkLandscape.png)" }}></div>
          </div>
          <div
            className="tiles__line"
            data-scroll
            data-scroll-speed="2"
            data-scroll-direction="horizontal"
            style={{ textAlign: "right", marginTop: "2vh", fontSize: "10px" }}
          >
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/rdeco2.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_portrait2.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/Finales.jpg)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_laces.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/columns2.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_sneakers.jpg)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/floorRoom.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/rdeco1.png)" }}></div>
          </div>
          <div
            className="tiles__line"
            data-scroll
            data-scroll-speed="-2"
            data-scroll-direction="horizontal"
            style={{ textAlign: "right", marginTop: "2vh", fontSize: "10px" }}
          >
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_run.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/rdeco4.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/text.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/IC_1.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/model_portrait2.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/mockup.jpg)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/columns1.png)" }}></div>
            <div className="tiles__line-img" style={{ backgroundImage: "url(./img/AI/IC_2.png)" }}></div>
          </div>
        </div>
        <div className="title-wrapper">
          <h2 className="titulo-AI">Generative AI</h2>
          <h1 className="subtitle-AI">— ComfyUI</h1>
        </div>
      </section>

      <div className="content-wrapper-AI" id="grid" data-scroll-section>
        <p className="text-left-ai">
          This is the section of my portfolio where I explore the creative possibilities of <strong>generative AI,</strong> where I bring creativity
          and technology together through AI-generated images. I’ve been using <strong>ComfyUI,</strong> a super flexible and powerful tool, to create
          unique visuals that blend artistic ideas with the most recent AI features. <br />
          <br />
          ComfyUI gives complete control over the entire process—from creating prompts to adjusting the final details—making it great for
          experimenting and exploring what AI can do in art and design.
        </p>
        <div className="basics-container">
          <h2 className="AI-title">The basics</h2>
          <div className="mosaic-image">
            <div className="image-row" data-scroll data-scroll-speed="1.2" data-scroll-direction="horizontal">
              <img className="grid-image" src="./img/AI/boat.png" alt="Boat" />
              <img className="grid-image" src="./img/AI/columns2.png" alt="Columns 2" />
              <img className="grid-image" src="./img/AI/floorRoom.png" alt="Floor Flowers" />
            </div>

            <div className="image-row" data-scroll data-scroll-speed="1.5" data-scroll-direction="horizontal">
              <img className="grid-image" src="./img/AI/pinkLandscape.png" alt="Pink Landscape" />
              <img className="grid-image" src="./img/AI/text.png" alt="Text" />
              <img className="grid-image" src="./img/AI/pink.png" alt="Pink" />
            </div>
          </div>
          <div className="basics-title">
            <p style={{ textAlign: "right", marginTop: "2vh" }} className="text-right-ai">
              In this Basics section, we’ll start by exploring how to create images using <strong>text prompts.</strong> I started with simple Stable
              Diffusion models like <strong>SDXL</strong>, combined with refining workflows to get the best possible quality.
              <br />
              <br />
              Now, with the arrival of more powerful models like <strong>Flux</strong>, which I've learned to integrate into my workflows, I've been
              able to achieve results like these—where even clear text can be added to the images. I've also incorporated <strong>LLM models</strong>{" "}
              into my workflows to refine prompts and bring the visuals I imagine to life.
            </p>
          </div>
          {/* --------------------------IMAGE 2 IMAGE---------------------------------------------------------------------------------------------------------- */}
          <h3 className="AI-subtitle-section" data-scroll data-scroll-speed="-0.1" data-scroll-direction="vertical" id="img2img">
            Image to Image
          </h3>
          <div className="mosaic-image-img2img">
            <div className="image-row-img2img" data-scroll data-scroll-speed="0.5" data-scroll-direction="vertical" data-scroll-target="#img2img">
              <div>
                <img className="grid-image-img2img" src="./img/AI/rdeco1.png" alt="Original" />
                <p>Original</p>
              </div>
              <div>
                <img className="grid-image-img2img" src="./img/AI/rdeco2.png" alt="Rustic" />
                <p>Rustic</p>
              </div>
              <div>
                <img className="grid-image-img2img" src="./img/AI/rdeco3.png" alt="Rococo" />
                <p>Rococo</p>
              </div>
              <div>
                <img className="grid-image-img2img" src="./img/AI/rdeco4.png" alt="Futuristic" />
                <p>Futuristic</p>
              </div>
            </div>
          </div>
          <div className="slider-img2img">
            <div className="slider-row-img2img">
              <SliderReveal image1="./img/AI/rdeco3.png" image2="./img/AI/rdeco4.png" />
              <p className="text-swiper">
                The next section focuses on image generation through <strong>image-to-image</strong>.
                <br />
                <br />
                This technique offers endless possibilities. In this case, I started with an original image created in ComfyUI. Then, by applying
                various <strong>IP Adapters</strong> and providing reference images featuring different decor styles, I transformed the same room into
                multiple versions with unique decorations—while <strong>keeping the original structure intact.</strong>
                <br />
                <br />
                One of the biggest advantages of using ComfyUI is the level of <strong>control</strong> it gives you over the images you’re creating.
                You can observe the similarities between the two images using the slider on the left.
              </p>
            </div>
          </div>
          {/* --------------------------IC_LIGHT---------------------------------------------------------------------------------------------------------- */}
          <h3 className="AI-subtitle-section">Product photography with IC-Light</h3>
          <div className="ic-container">
            <div className="ic-row-inspo">
              <div className="ic-cologne-inspo">
                <img className="ic-images" src="./img/AI/IC-p.png" />
                <p style={{ marginBottom: "0px" }}>Real image</p>
              </div>
              <div className="ic-inspo-column">
                <p>
                  The next section focuses on product photography with IC-Light in ComfyUI, specifically designed for enhancing product images.
                  <br />
                  <br />
                  <strong>IC-Light</strong>, based on SD1.5, allows us to use a reference background and an object photo to regenerate the background
                  and <strong>re-light</strong> the object.
                  <br />
                  <br />
                  Here, you can see the object we used as a reference—in this case, a bottle of cologne with text and a specific background. To ensure
                  the details of the cologne transferred seamlessly into the final result, several techniques were applied, such as masking
                  problematic areas and utilizing advanced nodes in the workflows like <strong>Detail Restore</strong> and
                  <strong> Color Match</strong>.
                </p>
                <div className="inspo-container">
                  <img className="ic-images-2" src="./img/AI/IC-inspo.jpg" />
                  <p style={{ marginBottom: "0px" }}>Inspo image</p>
                </div>
              </div>
            </div>
            <div className="ic-row-img1">
              <img className="ic-images-big" src="./img/AI/IC_1.png" />
            </div>
            <div className="ic-row-img1">
              <img className="ic-images-big" src="./img/AI/IC_2.png" />
            </div>
            <p className="text-right-ai">
              The possibilities are endless. For example, here I changed the background image, which creates a completely different{" "}
              <strong>lighting setup</strong> and adapts the cologne to any scenario.
              <br />
              <br />
              Also, IC-Light allows you to control the <strong>direction of the light.</strong> As you can clearly see in this image, the light is
              coming from the right-hand side, creating a distinct lighting effect that enhances the overall look of the product. This feature is
              especially useful for experimenting extensively with product photography and <strong>exploring a wide range of options</strong> for a
              campaign.
            </p>
          </div>
          {/* -------------------------- OUTPAINTING ---------------------------------------------------------------------------------------------------------- */}
          <h3 className="AI-subtitle-section">Outpainting & Scaling</h3>
          <div>
            <p style={{ marginBottom: "2%" }} className="text-left-ai">
              Let's say we continue with the campaign for this cologne, but we need different image formats—one in a more horizontal layout and
              another in a vertical one.
              <br />
              <br />
              ComfyUI also allows for <strong>outpainting</strong>. By masking the area we want to extend and using specialized models and techniques
              for this workflow, we can achieve really good results that allow us to create images in various sizes.
            </p>
            <div className="out-container">
              <div className="out-row-1">
                <img className="out-images" src="./img/AI/out_landscape_1.png" />
                <img className="out-images" src="./img/AI/out_portrait_1.png" />
              </div>
              <div className="out-row-1">
                <img className="out-images" src="./img/AI/out_landscape_2.png" />
                <img className="out-images" src="./img/AI/out_portrait_2.png" />
              </div>
            </div>
            <img className="out-mockup" src="./img/AI/mockup.jpg" />
            <p style={{ marginTop: "2%" }} className="text-left-ai">
              ComfyUI also allows you to <strong>scale images</strong> with high efficiency. By using specialized models like ClearReality or
              UltraSharp and advanced workflows, you can scale any image up or down without losing quality. This process involves
              <strong> upscaling</strong> techniques, which improve image resolution while preserving fine details. These methods help eliminate
              typical scaling issues like pixelation or loss of sharpness.
            </p>
          </div>
          {/* -------------------------- INPAINTING ---------------------------------------------------------------------------------------------------------- */}
          <h3 className="AI-subtitle-section" style={{ textAlign: "right", marginTop: "8vh" }}>
            Inpainting
          </h3>
          <div style={{ display: "grid" }}>
            <p className="text-right-ai" style={{ alignSelf: "start" }}>
              Just like outpainting, you can also do <strong>inpainting</strong> in ComfyUI. Inpainting refers to the process of
              <strong> filling in or removing</strong> parts of an image, typically used to eliminate unwanted objects or details. For this example, I
              used several images with cars in them, and thanks to inpainting techniques, I was able to erase all the cars from each image.
            </p>
          </div>
          <div className="in-container">
            <div className="in-col-1">
              <img className="in-images" src="./img/AI/3.jpg" />
              <img className="in-images" src="./img/AI/1.jpg" />
              <img className="in-images" src="./img/AI/2.jpg" />
            </div>
            <div className="in-col-2">
              <img className="in-images" src="./img/AI/3-in.png" />
              <img className="in-images" src="./img/AI/1_in.png" />
              <img className="in-images" src="./img/AI/2_in.png" />
            </div>
          </div>
        </div>
        {/* -------------------------- Style Transfer ---------------------------------------------------------------------------------------------------------- */}
        <div className="style-container">
          <h2 className="AI-title" style={{ marginBottom: "2vh" }}>
            Style Aligned
          </h2>
          <p className="text-left-ai">
            <strong>Style aligned</strong> is a technique that allows you to apply the style of one image to another, effectively merging the content
            of one with the artistic style of another. This can be used to transform the look of an image while keeping its original structure. Now,
            I'll be showing several examples of what can be achieved with this technique.
          </p>
          <h3 className="AI-subtitle-section" style={{ margin: "0 0 2vh 0" }}>
            Icons with a consistent style
          </h3>
          <div className="style-container">
            <div className="style-row">
              <img className="icon-images" src="./img/AI/1-web.png" />
              <img className="icon-images" src="./img/AI/2-web.png" />
              <img className="icon-images" src="./img/AI/3-web.png" />
              <img className="icon-images" src="./img/AI/4-web.png" />
              <img className="icon-images" src="./img/AI/5-web.png" />
              <p style={{ paddingLeft: "1vh" }}>
                In this first example, we’ll explore one of the possible applications of style aligned: Creating variations of images with a
                <strong> consistent style.</strong> In this case, we’ve created a set of icons with orange and green tones.
              </p>
            </div>
            <div className="style-row">
              <img className="icon-images" src="./img/AI/1-can.png" />
              <img className="icon-images" src="./img/AI/2-can.png" />
              <img className="icon-images" src="./img/AI/3-can.png" />
              <img className="icon-images" src="./img/AI/4-can.png" />
              <img className="icon-images" src="./img/AI/5-can.png" />
              <p style={{ paddingLeft: "1vh" }}>
                We can take this even further by giving each icon a completely different style, like a <strong>candy theme,</strong> as shown in these
                examples.
              </p>
            </div>
            {/* -------------------------- Sneakers ---------------------------------------------------------------------------------------------------------- */}
            <h3 className="AI-subtitle-section" style={{ margin: "4vh 0 2vh 0" }}>
              Style Transfer for fashion
            </h3>
            <div className="sneakers-container">
              <div>
                <p className="text-left-ai" style={{ marginBottom: "2%" }}>
                  Now, let’s say we want to create a unique piece. In this case, we’ll use a combination of <strong>Style Transfer</strong>,
                  <strong>IP Adapters</strong>, and <strong>ControlNets</strong> to achieve a design of sneakers with wings. <br />
                  <br />
                  <strong>Style Transfer</strong> will help us apply a creative style to the design, while <strong>IP Adapters</strong> allow us to
                  integrate specific details or textures. <strong>ControlNets</strong> will give us even more control over the generation process,
                  allowing us to fine-tune the placement of the wings on the sneakers and ensure that the final result matches our vision perfectly.
                  This approach allows for limitless creativity in producing completely unique designs.
                </p>
                <img className="sneakers-image" src="./img/AI/exp.png" />
                <img className="sneakers-image" src="./img/AI/Finales.jpg" />
              </div>
            </div>
          </div>
        </div>
        {/* -------------------------- Model Consistency ---------------------------------------------------------------------------------------------------------- */}
        <div className="model-container">
          <h2 className="AI-title" style={{ margin: "4vh 0 3vh 0" }}>
            Model Consistency
          </h2>
          <h3 className="AI-subtitle-section" style={{ margin: "0 0 2vh 0" }}>
            AI character
          </h3>
          <div className="model-container-img">
            <div className="sheet-row">
              <img className="sheet-image" src="./img/AI/model_sheet.jpg" />
              <p className="text-next-sheet">
                Let’s continue with the campaign for the sneakers we designed earlier. Now, we need a model for the campaign. To create our model, I
                started by generating a <strong>character sheet</strong> using <strong>Flux</strong>. This workflow allows us to create a detailed
                sheet of our character from multiple angles, ensuring consistency across different perspectives.
                <br />
                <br />
                Additionally, it enables us to adjust the character's emotions and generate all the images needed to train a custom
                <strong> Lora</strong>. Once trained, this Lora can be used to place our character in any pose or setting we want to fit the
                campaign's vision.
                <br />
                <br />
                By advanced workflows, including Flux models, and Lora training through pinokio, we can achieve consistent, high-quality results
                across all our images, taking AI art and storytelling to the next level. Below, you can see the images created for the sneaker
                campaign, showcasing our model in various settings—running on a track, posing with a basketball, or even tying their shoes.
              </p>
            </div>
            <div className="images-row">
              <img className="model-image" src="./img/AI/model_run.png" />
              <img className="model-image" src="./img/AI/model_portrait.jpg" />
              <img className="model-image" src="./img/AI/model_laces.png" />
            </div>
            <div className="report-row">
              <p className="text-photo-report">
                To demonstrate that we can truly place our character in any setting, we created an image that completely steps away from the
                campaign’s original aesthetic. This time, our model is dressed formally, attending an interview. This highlights the versatility of
                the custom Lora and the power of advanced workflows in adapting a character to entirely new contexts while maintaining{" "}
                <strong>consistency and quality.</strong>
              </p>
              <img className="model-report-image" src="./img/AI/model_press.png" />
            </div>
            <div className="final-model-row">
              <img className="final-model-image" src="./img/AI/model_sneakers.jpg" />
            </div>
            <p style={{ alignSelf: "start", marginTop: "1vh" }} className="text-right-ai">
              Here, you can see the final result of the "campaign" displayed on a billboard mockup. This showcases how AI tools can be used to bring
              an idea from concept to a final presentation.
              <br />
              <br />
              Reflecting on this process, it’s incredible to see how AI technology is constantly <strong>evolving.</strong> What we can learn and
              achieve today — designing characters, creating campaigns, and generating professional-quality visuals—was unimaginable just a few years
              ago. The potential for this technology in design and creativity is massive, and it’s exciting to think about where it might take us
              next.
            </p>
          </div>
          <div style={{ height: "10vh" }}></div>
        </div>
      </div>
      <div className="circle-3d"></div>
    </div>
  );
}

export default AI;
